/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useContext } from 'react';
import { PromptsContext } from '@/context';

export const usePrompts = () => {
  return useContext(PromptsContext);
};
