/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useEffect, useState } from 'react';

import { Filter, FilterGroup } from './prompt-interfaces';

export const usePromptFilters = (open: boolean) => {
  const [filterGroups, setFilterGroups] = useState<FilterGroup[]>([
    {
      id: Date.now(),
      filters: [
        { id: Date.now(), type: 'input', condition: 'contains', value: '' },
      ],
    },
  ]);
  const [appliedFilters, setAppliedFilters] = useState<FilterGroup[]>([]);
  const [tempFilterGroups, setTempFilterGroups] = useState<FilterGroup[]>([]);

  useEffect(() => {
    if (open) {
      setTempFilterGroups(
        JSON.parse(
          JSON.stringify(
            appliedFilters.length > 0 ? appliedFilters : filterGroups
          )
        )
      );
    }
  }, [open]);

  const addFilter = (groupIndex: number) => {
    const newFilterGroups = [...tempFilterGroups];
    newFilterGroups[groupIndex].filters.push({
      id: Date.now(),
      type: 'input',
      condition: 'contains',
      value: '',
    });
    setTempFilterGroups(newFilterGroups);
  };

  const removeFilter = (groupIndex: number, filterId: number) => {
    const newFilterGroups = [...tempFilterGroups];
    newFilterGroups[groupIndex].filters = newFilterGroups[
      groupIndex
    ].filters.filter((filter) => filter.id !== filterId);
    if (newFilterGroups[groupIndex].filters.length === 0) {
      newFilterGroups.splice(groupIndex, 1);
    }
    setTempFilterGroups(newFilterGroups);
  };

  const updateFilter = (
    groupIndex: number,
    filterId: number,
    updatedFilter: Filter
  ) => {
    const newFilterGroups = [...tempFilterGroups];
    const filterIndex = newFilterGroups[groupIndex].filters.findIndex(
      (filter) => filter.id === filterId
    );
    newFilterGroups[groupIndex].filters[filterIndex] = updatedFilter;
    setTempFilterGroups(newFilterGroups);
  };

  const addCondition = () => {
    setTempFilterGroups([
      ...tempFilterGroups,
      {
        id: Date.now(),
        filters: [
          { id: Date.now(), type: 'input', condition: 'contains', value: '' },
        ],
      },
    ]);
  };

  const validateFilters = (): boolean => {
    let isValid = true;
    const updatedFilterGroups = tempFilterGroups.map((group) => ({
      ...group,
      filters: group.filters.map((filter) => {
        if (!filter.value.trim()) {
          isValid = false;
          return { ...filter, error: true };
        }
        return { ...filter, error: false };
      }),
    }));
    setTempFilterGroups(updatedFilterGroups);
    return isValid;
  };

  const applyFilters = () => {
    if (validateFilters()) {
      setAppliedFilters(tempFilterGroups);
      setFilterGroups(tempFilterGroups);
      return true;
    }
    return false;
  };

  const resetTempFilters = () => {
    setTempFilterGroups(JSON.parse(JSON.stringify(appliedFilters)));
  };

  return {
    filterGroups,
    appliedFilters,
    tempFilterGroups,
    addFilter,
    removeFilter,
    updateFilter,
    addCondition,
    applyFilters,
    resetTempFilters,
  };
};
