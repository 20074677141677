/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useLanguage } from '@/providers/LanguageProvider';
import { LucideCircleMinus } from 'lucide-react';

import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { FilterLineProps } from './prompt-interfaces';

const FilterLine: React.FC<FilterLineProps> = ({
  filter,
  onRemove,
  showRemoveButton,
  onUpdate,
  isFirstGroup,
}) => {
  const { messages } = useLanguage();
  const langData = messages?.PromptFilters?.AddPromptCriteria;

  return (
    <div className="flex w-full items-center gap-3">
      <div className="flex-shrink-0">
        <Select
          value={isFirstGroup ? 'contains' : filter.condition}
          onValueChange={(value) => onUpdate({ ...filter, condition: value })}
          disabled={isFirstGroup}
        >
          <SelectTrigger className="min-w-[110px] whitespace-nowrap">
            <SelectValue placeholder="Select condition" />
          </SelectTrigger>
          <SelectContent className="rounded-none bg-white text-[#201F1E]">
            <SelectItem value="contains" className="hover:bg-[#EDEBE9]">
              {langData?.condition_contains}
            </SelectItem>
            <SelectItem value="does-not-contain" className="hover:bg-[#EDEBE9]">
              {langData?.condition_does_not_contain}
            </SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="flex flex-1 items-center">
        <Input
          required
          type="text"
          placeholder={langData?.placeholder}
          className={`h-[42px] flex-1 placeholder:text-[#8C9EA1] ${
            filter.error ? 'border-red-700' : ''
          }`}
          value={filter.value}
          onChange={(e) =>
            onUpdate({
              ...filter,
              value: e.target.value,
              error: false,
            })
          }
        />
      </div>

      <div className="flex flex-shrink-0 items-center gap-3">
        <span
          className={`uppercase text-[#8C9EA1] ${!showRemoveButton && 'mr-9'}`}
        >
          {langData?.or}
        </span>
        {showRemoveButton && (
          <div className="w-6">
            <LucideCircleMinus
              className="h-6 w-6 cursor-pointer text-link-active"
              onClick={onRemove}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterLine;
