/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import { DetailsPageSpinner } from '../spinner/details-page-spinner';
import { Skeleton } from '../ui/skeleton';

export const DetailsPageLoading = () => {
  return (
    <div>
      <Skeleton className="animate-pulse-custom mb-6 h-6 w-60 rounded-full bg-[#8C9EA1]" />
      <Skeleton className="animate-pulse-custom h-6 w-56 rounded-full bg-[#8C9EA1]" />
      <div className="space-y-[14px] pt-8">
        <Skeleton className="animate-pulse-custom h-2.5 w-[664px] rounded-full bg-[#8C9EA1]" />
        <Skeleton className="animate-pulse-custom h-2.5 w-[828px] rounded-full bg-[#8C9EA1]" />
        <Skeleton className="animate-pulse-custom h-2.5 w-[734px] rounded-full bg-[#8C9EA1]" />
      </div>
      <div className="py-10">
        <Skeleton className="animate-pulse-custom mb-4 h-6 w-[420px] rounded-full bg-[#8C9EA1]" />
        <div className="h-[420px] w-full rounded-md border border-line-secondary bg-canvas-background-primary">
          {/* loading spinner */}
          <DetailsPageSpinner />
        </div>
      </div>
      <div className="pb-10">
        <Skeleton className="animate-pulse-custom mb-3 h-6 w-[150px] rounded-full bg-[#8C9EA1]" />
        <div className="flex gap-x-[30.83px]">
          {[...Array(2)].map((item, i) => (
            <div
              key={i}
              className="h-[152.8px] w-full rounded-md border border-line-secondary bg-canvas-background-primary p-4"
            >
              {/* loading spinner */}
              <DetailsPageSpinner />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
