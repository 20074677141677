'use client';

import React from 'react';
import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';
import { AppGraph, Component } from 'types/api-data';

import { iconMap } from '@/lib/helper';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import { System } from '../icons';
import { Button } from '../ui/button';

export const DisplayItems = ({ data }: { data?: AppGraph }) => {
  const { messages } = useLanguage();
  const langData = messages?.ApplicationDetailPage;

  const dataTransform = (data: AppGraph) => {
    const servicesData = data.components.filter(
      (item: Component) => item.domain === 'infra'
    );
    return servicesData;
  };

  return (
    <div className="w-full">
      <h3 className="pb-3 text-h3 font-medium text-body-subtle">
        {messages?.General?.services}
      </h3>

      <div className="rounded-lg border border-line-secondary bg-canvas-background-primary px-2 py-3">
        {data && dataTransform(data).length > 0 ? (
          <>
            <div className="text-body-primary">
              {data &&
                dataTransform(data).map((item: Component) => (
                  <div
                    className="flex items-center gap-2.5 p-2.5"
                    key={item.display_name}
                  >
                    <span className="flex size-8 items-center justify-center rounded-full bg-white text-black">
                      <Image
                        className="size-5"
                        src={iconMap[item.type] ?? iconMap['default']}
                        alt="Logo"
                        width={100}
                        height={100}
                      />
                    </span>
                    <div className="flex flex-col">
                      <span className="text-body-sm font-normal text-link-active">
                        {item.type}
                      </span>
                      <h4 className="text-h4 font-medium">
                        {item.display_name}
                      </h4>
                    </div>
                  </div>
                ))}
            </div>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="m-2.5 inline-block cursor-not-allowed">
                    <Button variant="outline" size="xs" type="button" disabled>
                      <span className="mr-2 text-white">
                        {langData?.add_services}
                      </span>
                      <span className="-ml-0.5 rounded bg-[#EFDA1F] px-1.5 text-btn-label-sm text-[#07181E]">
                        {messages?.General?.wip}
                      </span>
                    </Button>
                  </div>
                </TooltipTrigger>
                <TooltipContent
                  side="bottom"
                  className="max-w-52 rounded border-transparent bg-[#07181E]"
                >
                  {langData?.adding_services_coming_soon_msg}
                  <TooltipArrow className="fill-[#07181E]" />
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </>
        ) : (
          <div className="flex flex-col items-center rounded-md p-4 text-white">
            {System.InfoIcon('size-6 text-form-input-disabled')}
            <p className="mb-3 mt-1 text-center text-xs">
              {langData?.you_dont_have_any_services_added}
            </p>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="inline-block cursor-not-allowed">
                    <Button variant="outline" size="xs" type="button" disabled>
                      <span className="mr-2">{langData?.add_services}</span>
                      <span className="-ml-0.5 rounded bg-[#EFDA1F] px-1.5 text-btn-label-sm text-[#07181E]">
                        {messages?.General?.wip}
                      </span>
                    </Button>
                  </div>
                </TooltipTrigger>
                <TooltipContent
                  side="bottom"
                  className="max-w-52 rounded border-transparent bg-[#07181E]"
                >
                  {langData?.adding_services_coming_soon_msg}
                  <TooltipArrow className="fill-[#07181E]" />
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>
    </div>
  );
};
