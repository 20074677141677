/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useLanguage } from '@/providers/LanguageProvider';

export const DisplayNotFound = () => {
  const { messages } = useLanguage();
  return (
    <div className="flex h-[calc(100vh-80px)] items-center justify-center text-body-subtle">
      <p className="mr-2 border-r border-gray-300 p-2 text-3xl">404</p>
      <p className="text-xl">
        {messages?.NotFound?.this_page_could_not_be_found}
      </p>
    </div>
  );
};
