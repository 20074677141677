/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import { AppGraph, LinkType, NodeType } from 'types/api-data';

import { graphDataTransform } from '@/lib/helper';

/**
 * Ref: https://github.com/NorthwoodsSoftware/gojs-react-basic/blob/master/src/components/DiagramWrapper.tsx
 * Diagram initialization method, which is passed to the ReactDiagram component.
 * This method is responsible for making the diagram and initializing the model and any templates.
 * The model's data should not be set here, as the ReactDiagram component handles that via the other props.
 */

function initDiagram() {
  const $ = go.GraphObject.make;
  // set your license key here before creating the diagram:
  go.Diagram.licenseKey = `${process.env.NEXT_PUBLIC_GOJS_LICENSE}`;

  const diagram = $(go.Diagram, {
    'undoManager.isEnabled': true, // must be set to allow for model change listening
    model: new go.GraphLinksModel({
      linkKeyProperty: 'key', // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
    }),
    layout: $(go.LayeredDigraphLayout),
  });

  // define a simple Node template
  diagram.nodeTemplate = $(
    go.Node,
    'Horizontal', // the Shape will go around the TextBlock
    $(
      go.Panel,
      'Vertical',
      // Panel to include a white background and the SVG icon
      $(
        go.Panel,
        'Auto',
        // { alignment: go.Spot.Left, alignmentFocus: go.Spot.Left },
        $(go.Shape, 'Circle', {
          fill: 'white', // White background
          stroke: null,
          desiredSize: new go.Size(48, 48), // Background size
        }),
        $(
          go.Picture,
          {
            name: 'Picture',
            // margin: 5, // Margin inside the background
            desiredSize: new go.Size(32, 32), // Size of the icon
          },
          new go.Binding('source') // Bind the source property to the node data
        )
      ),
      // TextBlock element for the text
      $(
        go.TextBlock,
        {
          font: '14px sans-serif',
          stroke: '#fff',
          margin: new go.Margin(5, 0, 0, 0), // Margin around the text
        },
        new go.Binding('text', 'name') // Bind the text property to the node data
      )
    )
  );

  diagram.linkTemplate = $(
    go.Link,
    {
      routing: go.Routing.AvoidsNodes, // avoid nodes while routing
      corner: 50, // rounding of the link's corners
      curve: go.Curve.JumpGap,
      curviness: 100,
    },
    $(go.Shape, { stroke: 'white' }),
    $(go.Shape, { toArrow: 'Standard', stroke: 'white' })
  );

  return diagram;
}

export const LayeredDigraphLayout = ({ data }: { data?: AppGraph }) => {
  let nodeDataArray: NodeType[] = [];
  let linkDataArray: LinkType[] = [];
  if (data) {
    const obj = graphDataTransform(data);
    nodeDataArray = obj.node;
    linkDataArray = obj.link;
  }
  return (
    <ReactDiagram
      initDiagram={initDiagram}
      divClassName="w-full h-full"
      nodeDataArray={nodeDataArray}
      linkDataArray={linkDataArray}
    />
  );
};
