/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React, { ReactNode, useCallback, useContext, useState } from 'react';
import { KPIContext } from '@/context';
import { request } from '@/services/request';
import { formatToLocalISOString } from '@/utils/formatedDates';
import { DateRange } from 'react-day-picker';
import { KPI } from 'types/kpi-data';

const generateDefaultDateRange = (): DateRange => {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
  sevenDaysAgo.setUTCHours(0, 0, 0, 0);

  const endOfDay = new Date();
  endOfDay.setUTCHours(23, 59, 59, 999);

  return { from: sevenDaysAgo, to: endOfDay };
};

export const KPIProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<{ [key: string]: KPI }>();
  const [loading, setLoading] = useState(false);
  const [currentAppName, setCurrentAppName] = useState<string>();
  const [fetchRange, setFetchRange] = useState<DateRange>();

  const fetchKPI = useCallback(async (appName: string, range?: DateRange) => {
    try {
      setLoading(true);
      setCurrentAppName(appName);

      // Use provided range or generate default
      const activeRange = range || generateDefaultDateRange();

      const from = activeRange.from || new Date();
      const to = activeRange.to || new Date();

      const queryParams = {
        start_time: formatToLocalISOString(from),
        end_time: formatToLocalISOString(to),
      };

      const fetchedData = await request.get({
        endpoint: `insights/apps/${appName}`,
        queryParams,
      });

      setData(fetchedData[0]);
      setFetchRange(activeRange);
    } catch (error) {
      console.error('Error fetching KPI data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const refreshKPIData = useCallback(() => {
    if (currentAppName && fetchRange) {
      fetchKPI(currentAppName, fetchRange);
    }
  }, [currentAppName, fetchRange, fetchKPI]);

  return (
    <KPIContext.Provider
      value={{
        data,
        loading,
        fetchKPI,
        refreshKPIData,
      }}
    >
      {children}
    </KPIContext.Provider>
  );
};

// Custom hook to use the KPI context
export const useKPIContext = () => {
  const context = useContext(KPIContext);
  return context;
};
