import { useTraces } from '@/hooks/useTraces';

import { SpanDetails } from '../display-page/traces/span-details';
import { useTraceSpanDetails } from '../display-page/traces/useTraceSpanDetails';

export const DisplayGanttChart = ({
  trace_id,
  workflow_name,
}: {
  trace_id: string;
  workflow_name: string;
}) => {
  const { traceSpanDetails } = useTraceSpanDetails({
    traceId: trace_id,
    workflowName: workflow_name,
  });

  const { state } = useTraces();
  const { traceSpanLoading } = state;

  return (
    <div>
      <SpanDetails
        spanDetails={traceSpanDetails.undefined?.[0]}
        traceSpanLoading={traceSpanLoading}
        readOnly
      />
    </div>
  );
};
