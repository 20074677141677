/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import React from 'react';
import { useRouter } from 'next/navigation';
import { useLanguage } from '@/providers/LanguageProvider';
import { KPI } from 'types/kpi-data';

import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import { OptimizationIcons, System } from '../icons';

export const WorkflowDetails = ({ data, sla }: { data?: KPI; sla: string }) => {
  const router = useRouter();
  const { messages } = useLanguage();
  const langData = messages?.ApplicationDetailPage;
  const roundNumber = (num: number, total: number, scale: number) => {
    return +((num * 100) / total).toFixed(scale);
  };

  const generateDefaultUrl = (additionalParams: string = '') => {
    const currentPath = window.location.pathname;
    const currentParams = window.location.search;
    const baseUrl = `${currentPath}/traces`;
    return `${baseUrl}${currentParams}&workflow=all${additionalParams}`;
  };

  // Helper to navigate based on status or SLA
  const handleNavigate = (status: string, hasValue: boolean, sla?: boolean) => {
    if (!hasValue) return; // No navigation if value is NA or 0
    let additionalParams = '';

    if (status === 'success') additionalParams = '&status=success';
    else if (status === 'error') additionalParams = '&status=error';
    else if (sla) additionalParams = '&sla=true';

    const newUrl = generateDefaultUrl(additionalParams);
    router.push(newUrl);
  };

  // Guard clause for non-clickable cases (NA or 0)
  const isClickable = (value: number | undefined | null) => {
    return value !== undefined && value !== null && value !== 0;
  };

  // TODO: In future iterations, handle displaying details for all workflows.
  const workflow = data?.workflows[0]; // Show only the 0th element for this release

  return (
    <>
      {workflow && (
        <div className="flex gap-16">
          {/* Success and Error Latency */}
          <div className="border-l border-line-secondary pl-[9px]">
            <div className="flex items-center gap-1">
              {OptimizationIcons.SpeedIcon('size-[20px] text-[#FFCB78]')}
              <p className="text-form-input-text text-body-primary">
                {langData?.avg_latency}
              </p>
            </div>
            <div className="flex items-center gap-5 pt-[9px]">
              <div
                className={
                  isClickable(workflow.avg_success_latency_ms)
                    ? 'cursor-pointer'
                    : ''
                }
                onClick={() =>
                  handleNavigate(
                    'success',
                    workflow.avg_success_latency_ms !== undefined
                  )
                }
              >
                <p className="pb-1 text-[9px] font-medium uppercase leading-[10.56px] text-body-primary">
                  {messages?.General?.success}
                </p>
                <p className="text-kpi-value text-link-active">
                  {workflow.avg_success_latency_ms === undefined ? (
                    <span className="text-form-input-disabled opacity-[.48]">
                      {langData?.na}
                    </span>
                  ) : (
                    <span>
                      {workflow.avg_success_latency_ms} {langData?.ms}
                    </span>
                  )}
                </p>
              </div>
              <div
                className={
                  isClickable(workflow.avg_error_latency_ms)
                    ? 'cursor-pointer'
                    : ''
                }
                onClick={() =>
                  handleNavigate(
                    'error',
                    workflow.avg_error_latency_ms !== undefined
                  )
                }
              >
                <p
                  aria-label="Error Latency"
                  className="pb-1 text-[9px] font-medium uppercase leading-[10.56px] text-body-primary"
                >
                  {messages?.General?.error}
                </p>
                <p className="text-kpi-value text-link-active">
                  {workflow.avg_error_latency_ms === undefined ? (
                    <span className="text-form-input-disabled opacity-[.48]">
                      {langData?.na}
                    </span>
                  ) : (
                    <span>
                      {workflow.avg_error_latency_ms} {langData?.ms}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          {/* Total Requests and SLA Met */}
          <div className="border-l border-line-secondary pl-[9px]">
            <div className="flex items-center gap-1">
              {OptimizationIcons.ErrorsIcon('size-[18px] text-[#FFCB78]')}
              <p className="text-form-input-text text-body-primary">
                {langData?.requests}
              </p>
            </div>
            <div className="flex items-center gap-5 pt-[9px]">
              <div
                className={
                  isClickable(workflow.request_count) ? 'cursor-pointer' : ''
                }
                onClick={() =>
                  handleNavigate('total', workflow.request_count !== 0)
                }
              >
                <p className="pb-1 text-[9px] font-medium uppercase leading-[10.56px] text-body-primary">
                  {langData?.total}
                </p>
                <p className="text-kpi-value text-link-active">
                  {workflow.request_count}
                </p>
              </div>
              <div
                className={
                  isClickable(workflow.meet_sla_count) ? 'cursor-pointer' : ''
                }
                onClick={() =>
                  handleNavigate('sla', workflow.meet_sla_count !== 0, true)
                }
              >
                <div className="flex items-center gap-x-1 pb-1">
                  <p className="text-[9px] font-medium uppercase leading-[10.56px] text-body-primary">
                    {langData?.met_sla}
                  </p>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        {System.InfoIcon('size-4 text-icon-fill')}
                      </TooltipTrigger>
                      <TooltipContent
                        side="bottom"
                        className="max-w-52 rounded border-transparent bg-[#07181E] text-body-sm text-white"
                      >
                        {sla}
                        <TooltipArrow className="fill-[#07181E]" />
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>

                <p className="text-kpi-value text-link-active">
                  {workflow.meet_sla_count !== 0 ? (
                    <span>
                      {roundNumber(
                        workflow.meet_sla_count,
                        workflow.request_count,
                        1
                      )}
                      %
                    </span>
                  ) : (
                    <span data-testid="workflow-met-sla">0%</span>
                  )}
                </p>
              </div>
              <div
                className={
                  isClickable(workflow.error_count) ? 'cursor-pointer' : ''
                }
                onClick={() =>
                  handleNavigate('error', workflow.error_count !== 0)
                }
              >
                <p
                  aria-label="Error"
                  className="pb-1 text-[9px] font-medium uppercase leading-[10.56px] text-body-primary"
                >
                  {messages?.General?.error}
                </p>
                <p className="text-kpi-value text-link-active">
                  {workflow.error_count !== 0 ? (
                    <span>
                      {roundNumber(
                        workflow.error_count,
                        workflow.request_count,
                        1
                      )}
                      %
                    </span>
                  ) : (
                    <span data-testid="workflow-error">0%</span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
