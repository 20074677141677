/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useState } from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import { constructPromptCriteriaFilterString } from '@/utils/constructPromptCriteriaFilterString';
import { FilterCriteriaGroup } from 'types/prompt';

import { usePrompts } from '@/hooks/usePrompts';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { MultiSelect } from '@/components/combobox';
import { System } from '@/components/icons';
import { PromptsListing } from '@/components/prompts-listing';

import { AddPromptCriteriaFilters } from './add-criteria-filter/add-promt-criteria-filters';
import { DateRange } from './date-range-picker/calendar-helper';
import { DateRangePicker } from './date-range-picker/date-range';

const PromptsPage = () => {
  const [appliedFilters, setAppliedFilters] = useState<FilterCriteriaGroup[]>(
    []
  );
  const {
    todaysDate,
    handleFormSubmit,
    appDataloading,
    transformedWorkflows,
    transformedModels,
    transformedServices,
    transformedProviders,
  } = usePrompts();

  const [multiSelect, setMultiselect] = useState({
    workflow_names: '',
    model_names: '',
    services: '',
    providers: '',
  });

  const [dateRange, setDateRange] = useState<{
    start_time: null | string;
    end_time: null | string;
  }>({
    start_time: '',
    end_time: '',
  });

  const { messages } = useLanguage();

  const handleFilterChange = (newFilters: FilterCriteriaGroup[]) =>
    setAppliedFilters(newFilters);

  const handleSearch = () => {
    const filterString = constructPromptCriteriaFilterString(appliedFilters);
    handleFormSubmit({
      filter: filterString,
      ...multiSelect,
      ...dateRange,
    });
  };

  const handleMultiSelect = (key: string, valueSelected: string[]) => {
    setMultiselect((prev) => ({ ...prev, [key]: valueSelected.join(',') }));
  };

  const handleDateRangeChange = (range: DateRange) => {
    const startDate = range.from ? new Date(range.from) : null;

    if (startDate) {
      startDate.setHours(0, 0, 0, 0);
      startDate.setMinutes(
        startDate.getMinutes() - startDate.getTimezoneOffset()
      );
    }

    const endDate = range.to ? new Date(range.to) : null;
    if (endDate) {
      endDate.setHours(23, 59, 59, 999);
      endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
    }

    setDateRange((prev) => ({
      ...prev,
      start_time: startDate ? new Date(startDate).toISOString() : null,
      end_time: endDate ? new Date(endDate).toISOString() : null,
    }));
  };

  return (
    <div className="mb-5 flex min-h-[73vh] rounded-lg border border-line-secondary bg-card-00">
      <div className="h-[973px] w-[280px] overflow-y-auto">
        <div className="space-y-6 p-5">
          <div>
            <p className="mb-2 flex items-center gap-1">
              <span className="text-form-input-text text-body-primary">
                {messages?.PromptFilters?.date_range?.time_range}
              </span>
              <span>{System.InfoIcon('size-3 text-[#257C9D]')}</span>
            </p>
            {appDataloading ? (
              <Skeleton className="animate-pulse-custom h-[66px] rounded-lg bg-[#8C9EA1]" />
            ) : (
              <DateRangePicker
                onRangeChange={handleDateRangeChange}
                todaysDate={todaysDate}
              />
            )}
          </div>

          <div>
            <p className="mb-2 flex items-center gap-1">
              <span className="text-form-input-text text-body-primary">
                {messages?.General?.prompts}
              </span>
              <span>{System.InfoIcon('size-3 text-[#257C9D]')}</span>
            </p>
            {appDataloading ? (
              <Skeleton className="animate-pulse-custom h-10 rounded-lg bg-[#8C9EA1]" />
            ) : (
              <AddPromptCriteriaFilters onFilterChange={handleFilterChange} />
            )}
          </div>

          <div className="space-y-2">
            <p className="text-form-input-text text-body-primary">
              {messages?.PromptFilters.multi_select.workflow}
            </p>
            {appDataloading ? (
              <Skeleton className="animate-pulse-custom h-10 rounded-lg bg-[#8C9EA1]" />
            ) : (
              <MultiSelect
                options={transformedWorkflows}
                onValueChange={(valueSelected) =>
                  handleMultiSelect('workflow_names', valueSelected)
                }
                placeholder={
                  messages?.PromptFilters.multi_select?.select_workflow
                }
                variant="inverted"
                maxCount={2}
                loading={appDataloading}
              />
            )}
          </div>

          <div className="space-y-2">
            <p className="text-form-input-text text-body-primary">
              {messages?.General?.model}
            </p>
            {appDataloading ? (
              <Skeleton className="animate-pulse-custom h-10 rounded-lg bg-[#8C9EA1]" />
            ) : (
              <MultiSelect
                options={transformedModels}
                onValueChange={(valueSelected) =>
                  handleMultiSelect('model_names', valueSelected)
                }
                placeholder={messages?.PromptFilters.multi_select.select_model}
                variant="inverted"
                maxCount={2}
                loading={appDataloading}
              />
            )}
          </div>

          <div className="space-y-2">
            <p className="text-form-input-text text-body-primary">
              {messages?.General?.service}
            </p>
            {appDataloading ? (
              <Skeleton className="animate-pulse-custom h-10 rounded-lg bg-[#8C9EA1]" />
            ) : (
              <MultiSelect
                options={transformedServices}
                onValueChange={(valueSelected) =>
                  handleMultiSelect('services', valueSelected)
                }
                placeholder={
                  messages?.PromptFilters.multi_select.select_service
                }
                variant="inverted"
                maxCount={2}
                loading={appDataloading}
              />
            )}
          </div>

          <div className="space-y-2">
            <p className="text-form-input-text text-body-primary">
              {messages?.General?.provider}
            </p>
            {appDataloading ? (
              <Skeleton className="animate-pulse-custom h-10 rounded-lg bg-[#8C9EA1]" />
            ) : (
              <MultiSelect
                options={transformedProviders}
                onValueChange={(valueSelected) =>
                  handleMultiSelect('providers', valueSelected)
                }
                placeholder={
                  messages?.PromptFilters.multi_select.select_provider
                }
                variant="inverted"
                maxCount={2}
                loading={appDataloading}
              />
            )}
          </div>
          <Button
            onClick={handleSearch}
            disabled={appDataloading}
            className={`w-full rounded-sm`}
          >
            {messages?.General?.search}
          </Button>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto border-l border-line-secondary">
        <PromptsListing />
      </div>
    </div>
  );
};

export { PromptsPage };
