/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React from 'react';
import { useLanguage } from '@/providers/LanguageProvider';

import { useAppDetails } from '@/hooks/useAppDetails';
import { ComponentDisplayItems, DisplayItems } from '@/components/items';
import { KPIItems } from '@/components/kpi';
import { DetailsPageLoading } from '@/components/loading';
import { ObservationCards } from '@/components/observations';
import { ServicesGraph } from '@/components/services-graph';

const InsightsPage = ({ appName }: { appName: string }) => {
  const { messages } = useLanguage();
  const langData = messages?.General;

  const {
    data,
    loading,
    addedComponent,
    setAddedComponent,
    appData,
    fetchAppDetails,
    setAppData,
  } = useAppDetails();

  if (loading || !data) {
    return <DetailsPageLoading />;
  }

  return (
    <>
      <KPIItems appName={appName} sla={data.sla} />
      <ServicesGraph
        data={data}
        loading={loading}
        onRefresh={fetchAppDetails}
        appData={appData}
        addedComponent={addedComponent}
        setAddedComponent={setAddedComponent}
        setAppData={setAppData}
      />
      <ObservationCards />
      {loading ? (
        <div className="text-white">{langData?.loading}</div>
      ) : (
        <div className="flex gap-x-[30.83px] pb-5">
          <DisplayItems data={data} />
          <ComponentDisplayItems
            data={data}
            onRefresh={fetchAppDetails}
            appDetails={appData}
            addedComponent={addedComponent}
            setAddedComponent={setAddedComponent}
            setAppDetails={setAppData}
          />
        </div>
      )}
    </>
  );
};
export { InsightsPage };
