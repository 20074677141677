/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useEffect } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useKPIContext } from '@/providers/KpiProvider';
import { ALL_TIME_START_DATE } from '@/utils/constants';
import { formatToLocalISOString } from '@/utils/formatedDates';

import { DateRange } from '../display-page/prompts/date-range-picker/calendar-helper';

const adjustDateTime = (date: Date) => {
  const adjustedDate = new Date(date);
  adjustedDate.setHours(0, 0, 0, 0);
  return adjustedDate;
};

const prepareEndDateForAPI = (date: Date) => {
  const apiEndDate = new Date(date);
  apiEndDate.setHours(23, 59, 59, 999);
  return apiEndDate;
};

const useKPI = (appName: string) => {
  const { data, loading, fetchKPI } = useKPIContext();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  const KPIData = data ? data[appName] : undefined;

  const handleDateRangeApply = (values: { range: DateRange }) => {
    const { range } = values;

    if (!range.from && !range.to) {
      const startTime = new Date(ALL_TIME_START_DATE);
      const endTime = new Date();

      const apiRange = {
        from: adjustDateTime(startTime),
        to: prepareEndDateForAPI(endTime),
      };
      const params = new URLSearchParams(searchParams);
      params.set('days', 'all');
      params.delete('start');
      params.delete('end');

      router.replace(`${pathname}?${params.toString()}`, { scroll: false });
      fetchKPI(appName, apiRange);
    } else if (range.from && range.to) {
      const adjustedRange = {
        from: adjustDateTime(range.from),
        to: adjustDateTime(range.to),
      };

      const apiRange = {
        from: adjustDateTime(range.from),
        to: prepareEndDateForAPI(range.to),
      };

      const params = new URLSearchParams(searchParams);
      params.delete('days');
      params.set('start', formatToLocalISOString(adjustedRange.from));
      params.set('end', formatToLocalISOString(adjustedRange.to));

      router.replace(`${pathname}?${params.toString()}`, { scroll: false });
      fetchKPI(appName, apiRange); // Pass the API-prepared range
    }
  };

  useEffect(() => {
    const daysParam = searchParams.get('days');
    const startParam = searchParams.get('start');
    const endParam = searchParams.get('end');

    if (daysParam === 'all') {
      const startTime = new Date(ALL_TIME_START_DATE);
      const endTime = new Date();
      const apiRange = {
        from: adjustDateTime(startTime),
        to: prepareEndDateForAPI(endTime),
      };

      fetchKPI(appName, apiRange);
    } else if (startParam && endParam) {
      const range = {
        from: adjustDateTime(new Date(startParam)),
        to: prepareEndDateForAPI(new Date(endParam)),
      };

      fetchKPI(appName, range);
    } else {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);

      fetchKPI(appName, {
        from: adjustDateTime(sevenDaysAgo),
        to: prepareEndDateForAPI(new Date()),
      });
    }
  }, [appName, fetchKPI]);

  return { KPIData, loading, handleDateRangeApply };
};

export default useKPI;
