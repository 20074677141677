/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useEffect } from 'react';

import { useAuth } from '@/hooks/useAuth';

export const SessionDetails = ({ session }: { session: string }) => {
  const { setAuth } = useAuth();
  useEffect(() => {
    setAuth(JSON.parse(session));
  }, [session]);

  return <></>;
};
