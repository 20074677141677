/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useLanguage } from '@/providers/LanguageProvider';
import { LucidePencil } from 'lucide-react';

import { AppliedFiltersProps } from './prompt-interfaces';

const AppliedFilters: React.FC<AppliedFiltersProps> = ({
  filterGroups,
  onEdit,
}) => {
  const { messages } = useLanguage();
  const langData = messages?.PromptFilters?.AddPromptCriteria;

  return (
    <div className="flex max-h-40 items-start justify-between gap-4 overflow-y-auto rounded border border-[#8C9EA1] bg-[#07181E] p-3 text-xs font-medium leading-[18px] text-[#8C9EA1]">
      <div>
        {filterGroups.map((group, groupIndex) => (
          <div key={group.id}>
            {group.filters.map((filter, filterIndex) => (
              <span key={filter.id}>
                {filterIndex > 0 && <span> {langData?.or} </span>}
                <span className="font-semibold text-white">
                  {filter.type}
                </span>{' '}
                {filter.condition}{' '}
                <span className="font-semibold text-white">{filter.value}</span>
              </span>
            ))}
            {groupIndex < filterGroups.length - 1 && (
              <span className="lowercase"> {langData?.and} </span>
            )}
          </div>
        ))}
      </div>
      <div>
        <LucidePencil
          className="h-4 w-4 cursor-pointer text-link-active"
          onClick={onEdit}
        />
      </div>
    </div>
  );
};

export default AppliedFilters;
