/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/
import * as React from 'react';
import { useLanguage } from '@/providers/LanguageProvider';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { Separator } from '../ui/separator';

export const SelectPromptGroup = ({
  selectedGrouping,
  setSelectedGrouping,
  setCurrentPage,
}: {
  selectedGrouping: string;
  setSelectedGrouping: React.Dispatch<React.SetStateAction<string>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { messages } = useLanguage();
  const langData = messages?.PromptsListing;

  return (
    <Select
      defaultValue={selectedGrouping}
      onValueChange={(value: string) => {
        if (value === 'clear') {
          setSelectedGrouping('');
        } else {
          setSelectedGrouping(value);
        }
        setCurrentPage(1);
      }}
    >
      <SelectTrigger className="h-7 w-[155px]">
        <SelectValue placeholder={langData?.select_a_category} />
      </SelectTrigger>
      <SelectContent className="rounded-none bg-white text-[#201F1E]">
        <SelectGroup>
          {!(selectedGrouping?.length > 0) && (
            <>
              <SelectLabel className="font-normal">
                {langData?.select_a_category}
              </SelectLabel>
              <Separator className="border border-[#EDEBE9] bg-[#EDEBE9]" />
            </>
          )}

          <SelectItem value="clear" className="hidden">
            {langData?.select_a_category}
          </SelectItem>

          <SelectItem
            value="model_name"
            className="focus:bg-[#EDEBE9] focus:text-[#201F1E]"
          >
            {messages?.General?.model}
          </SelectItem>
          <SelectItem
            value="provider_name"
            className="focus:bg-[#EDEBE9] focus:text-[#201F1E]"
          >
            {' '}
            {messages?.General?.provider}
          </SelectItem>
          {selectedGrouping?.length > 0 && (
            <>
              <Separator className="border-[1px] border-[#EDEBE9] bg-[#EDEBE9]" />
              <SelectItem
                value="clear"
                className="focus:bg-[#EDEBE9] focus:text-[#201F1E]"
              >
                {langData?.clear_grouping}
              </SelectItem>
            </>
          )}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
