/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
import { FilterCriteriaGroup } from 'types/prompt';

export const constructPromptCriteriaFilterString = (
  filterGroups: FilterCriteriaGroup[]
) => {
  return filterGroups
    .map((group) => {
      const groupFilters = group.filters
        .map((filter: any) => {
          let field = filter.type.toLowerCase();
          if (field === 'any') field = 'prompts';

          // 'not contains' condition
          if (filter.condition === 'does-not-contain') {
            return `not contains(${field}, '${filter.value}')`;
          }

          return `contains(${field}, '${filter.value}')`;
        })
        .filter(Boolean);

      // Join filters within a group with 'or'
      return groupFilters.length > 1
        ? `(${groupFilters.join(' or ')})`
        : groupFilters[0];
    })
    .join(' and '); // Join groups with 'and'
};
