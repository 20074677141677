/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useContext } from 'react';
import { TourContext } from '@/context';

export const useStartTour = () => {
  return useContext(TourContext);
};
