/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/

export const formatDurationText = (text: string) => {
  const parts = text.split('(');
  if (parts.length === 2) {
    return (
      <div className="flex items-center gap-1">
        <span className="text-body-subtle">
          <span className="text-body-primary">{parts[0]}</span>({parts[1]}
        </span>
      </div>
    );
  }
  return text;
};
