import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useLanguage } from '@/providers/LanguageProvider';
import Edit from 'public/shared-icons/Edit.svg';
import Trash from 'public/shared-icons/Trash.svg';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import { Icons } from '../icons';
import { DeleteAppDialog } from './delete-dialog';

export const TitleDropDown = ({
  name,
  title,
}: {
  name: string;
  title: string;
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { locale, messages } = useLanguage();
  const general = messages?.General;

  return (
    <div>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          {Icons.Ellipses('border border-white text-white')}
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="min-w-32 rounded-sm bg-white text-[#323130]"
        >
          <DropdownMenuItem className="hover:bg-[#EDEBE9]">
            <Link
              href={`/${locale}/apps/edit-app/${name}`}
              className="flex cursor-pointer items-center gap-2"
            >
              <Image src={Edit} alt="edit icon" className="h-4 w-4" />
              <span>{general?.edit}</span>
            </Link>
          </DropdownMenuItem>

          <DropdownMenuItem
            className="flex cursor-pointer items-center gap-2 hover:bg-[#EDEBE9]"
            onClick={() => setOpenDeleteDialog(true)}
          >
            <Image src={Trash} alt="delete icon" className="h-4 w-4 stroke-2" />
            <span>{general?.delete}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      {openDeleteDialog && (
        <DeleteAppDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          title={title}
          name={name}
        />
      )}
    </div>
  );
};
