/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import React, { Dispatch, SetStateAction } from 'react';
import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';
import { formatDateMonthTime } from '@/utils/formatedDates';
import { IPrompt } from 'types/prompt';

import { formatSecondsAndMilliseconds, iconMap } from '@/lib/helper';
import { Checkbox } from '@/components/ui/checkbox';

export const PromptCard = ({
  selectedPrompt,
  setSelectedPrompt,
  item,
}: {
  selectedPrompt: IPrompt[];
  setSelectedPrompt: Dispatch<SetStateAction<IPrompt[]>>;
  item: IPrompt;
}) => {
  const { messages } = useLanguage();
  const langData = messages?.General;

  const isChecked = selectedPrompt.some(
    (prompt) => prompt.trace_id === item.trace_id
  );

  const handleCheckedChange = (checked: boolean) => {
    if (checked) {
      setSelectedPrompt((prev) => [...prev, item]);
    } else {
      setSelectedPrompt((prev) =>
        prev.filter((prompt) => prompt.trace_id !== item.trace_id)
      );
    }
  };

  const start = new Date(item?.start_time).valueOf();
  const end = new Date(item?.end_time).valueOf();
  const duration = formatSecondsAndMilliseconds(end - start);

  return (
    <div
      className={`flex cursor-pointer gap-3 rounded border p-4 ${
        isChecked
          ? 'border-nav-selected-state bg-[#0A343C]'
          : 'border-line-tertiary bg-[#0A2D3A] hover:bg-[#0A343C]'
      }`}
    >
      <Checkbox
        checked={isChecked}
        onCheckedChange={handleCheckedChange}
        onClick={(e) => e.stopPropagation()}
        id={item?.trace_id}
        className="h-[18px] w-[18px] border-form-control-border-rest"
      />
      <div className="mr-2 w-full ">
        <div className="mb-3.5 flex justify-between">
          <div>
            <p className="text-form-input-text text-body-subtle">
              {langData?.duration}: {duration}
            </p>
            <p className="pt-[1px] text-body text-body-primary">
              {formatDateMonthTime(item?.start_time)}{' '}
              <span className="text-[11px]">{langData?.to}</span>{' '}
              {formatDateMonthTime(item?.end_time)}
            </p>
          </div>

          <div className="flex gap-6 pr-3.5">
            {item?.model_attributes?.provider_name && (
              <div className="flex items-center gap-2">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
                  <Image
                    className="size-6"
                    src={
                      iconMap[item?.model_attributes?.provider_type] ??
                      iconMap['default']
                    }
                    alt={`${
                      item?.model_attributes?.provider_type ?? 'default'
                    } provider`}
                    width={100}
                    height={100}
                  />
                </div>
                <div>
                  <p className="text-form-input-text font-normal text-form-input-disabled">
                    {langData?.provider}
                  </p>
                  <p className="pt-[1px] text-body text-body-primary">
                    {item?.model_attributes?.provider_name}
                  </p>
                </div>
              </div>
            )}

            {item?.model_name && (
              <div className="flex items-center gap-2">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white">
                  <Image
                    className="size-6"
                    src={iconMap['model.llm']}
                    alt={'model.llm icon'}
                    width={100}
                    height={100}
                  />
                </div>

                <div>
                  <p className="text-form-input-text font-normal text-form-input-disabled">
                    {langData?.model}
                  </p>
                  <p className="pt-[1px] text-body text-body-primary">
                    {item?.model_name}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex w-full rounded-sm border border-[#40565A]">
          <div className="w-[240px] shrink-0 px-3 py-3 text-form-input-text">
            <p className="mb-1 text-form-input-disabled">{langData?.input}:</p>
            <p className="text-body-primary">{item?.input?.question}</p>
          </div>
          <div className="border-l border-[#40565A] px-3 py-3 text-form-input-text">
            <p className="mb-1 text-form-input-disabled">{langData?.output}:</p>
            <p className="text-body-primary">{item?.output?.response}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
