/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useLanguage } from '@/providers/LanguageProvider';
import { AppGraph } from 'types/api-data';

import { TitleDropDown } from '../detail-page';

export const TitleBar = ({ data }: { data: AppGraph }) => {
  const { messages } = useLanguage();
  return (
    <div className="mb-3">
      <div className="flex items-center justify-between">
        <div className="flex gap-6">
          <h1 className="text-h1 text-body-primary">{data.display_name}</h1>
        </div>
        <div className="flex items-center gap-x-5">
          {/* The below two icons are removed till such time the feature becomes public */}
          {/* {Icons.Share('stroke-form-input-disabled')}
          {Icons.Flag('stroke-form-input-disabled')} */}
          <div className="cursor-pointer">
            <TitleDropDown title={data.display_name} name={data.name} />
          </div>
        </div>
      </div>
      <div className="flex gap-4 text-sm text-form-input-disabled">
        <p>
          {messages?.ApplicationDetailPage?.id}: {data.name}
        </p>
        {data?.owner.trim() ? (
          <p>
            {messages?.General?.owner}:{' '}
            <span className="text-link-active">{data?.owner}</span>
          </p>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
