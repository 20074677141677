/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React, { useEffect, useState } from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import { LucidePlus } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

import AppliedFilters from './section/applied-filters';
import PromptFilterGroup from './section/prompt-filter-group';
import { usePromptFilters } from './section/usePromptCriteriaFilters';

const MAX_FILTER_GROUPS = 3;

interface AddPromptCriteriaFiltersProps {
  onFilterChange: (filters: any[]) => void;
}

const AddPromptCriteriaFilters: React.FC<AddPromptCriteriaFiltersProps> = ({
  onFilterChange,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const { messages } = useLanguage();
  const langData = messages?.PromptFilters?.AddPromptCriteria;

  const {
    appliedFilters,
    tempFilterGroups,
    addFilter,
    removeFilter,
    updateFilter,
    addCondition,
    applyFilters,
    resetTempFilters,
  } = usePromptFilters(open);

  const handleApply = () => applyFilters() && setOpen(false);

  useEffect(() => {
    onFilterChange(appliedFilters);
  }, [appliedFilters, onFilterChange]);

  const handleCancel = () => {
    resetTempFilters();
    setOpen(false);
  };
  const canAddMoreGroups = tempFilterGroups.length < MAX_FILTER_GROUPS;

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          {appliedFilters.length > 0 ? (
            <AppliedFilters
              filterGroups={appliedFilters}
              onEdit={() => setOpen(true)}
            />
          ) : (
            <Button
              variant="outline"
              className="h-[42px] w-full gap-0.5 text-[13px] leading-[15.25px] text-[#EBEFF1]"
            >
              <LucidePlus className="text-[#C1C9CF]" size={18} />
              {langData?.title}
            </Button>
          )}
        </DialogTrigger>
        <DialogContent
          aria-describedby={undefined}
          className="flex max-h-[80vh] max-w-[960px] flex-col gap-[10px] border-link-active bg-gradient-sky-dark p-8"
        >
          <DialogHeader>
            <DialogTitle className="border-b border-line-primary pb-5 text-2xl font-normal">
              {langData?.dialog_title}
            </DialogTitle>
          </DialogHeader>
          <div className="flex-grow overflow-y-auto">
            {tempFilterGroups.map((group, index) => (
              <PromptFilterGroup
                key={group.id}
                group={group}
                onAddFilter={() => addFilter(index)}
                onRemoveFilter={(filterId) => removeFilter(index, filterId)}
                onUpdateFilter={(filterId, updatedFilter) =>
                  updateFilter(index, filterId, updatedFilter)
                }
                isFirstGroup={index === 0}
                isLastGroup={
                  index === tempFilterGroups.length - 1 && !canAddMoreGroups
                }
              />
            ))}
            {canAddMoreGroups && (
              <div className="mb-[10px] flex justify-center rounded border border-dashed border-[#40565A]">
                <div
                  className="flex cursor-pointer items-center justify-center p-3 text-[13px] leading-[18px] text-link-active"
                  onClick={addCondition}
                >
                  <LucidePlus size={18} /> {langData?.add_and_condition}
                </div>
              </div>
            )}
          </div>
          <DialogFooter className="space-x-2 border-t border-line-primary pt-5 sm:justify-start">
            <Button size="sm" className="h-[28px]" onClick={handleApply}>
              {langData?.apply}
            </Button>
            <Button
              variant="outline"
              size="sm"
              className="h-[28px]"
              onClick={handleCancel}
            >
              {messages?.General?.cancel}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export { AddPromptCriteriaFilters };
