/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/
import { Dispatch, SetStateAction, useState } from 'react';
import { IPrompt } from 'types/prompt';

import { usePrompts } from '@/hooks/usePrompts';

import { Dialog, DialogTrigger } from '../ui/dialog';
import { PromptAccordion } from './prompt-accordion';
import { PromptCard } from './prompt-card';
import { PromptDetailsModal } from './prompt-detail-modal';

export const PromptListAll = ({
  accordionValue,
  setAccordionValue,
  currentPrompts,
  selectedPrompt,
  setSelectedPrompt,
  selectedGrouping,
  startIndex,
}: {
  accordionValue: string;
  setAccordionValue: Dispatch<SetStateAction<string>>;
  currentPrompts: IPrompt[];
  selectedPrompt: IPrompt[];
  setSelectedPrompt: Dispatch<SetStateAction<IPrompt[]>>;
  selectedGrouping: string;
  startIndex: number;
}) => {
  const [position, setPosition] = useState<number>(0);
  const { state } = usePrompts();
  const promptList = state?.promptData;

  const handleNext = () => {
    setPosition((prev) => prev + 1);
  };
  const handlePrev = () => {
    setPosition((prev) => prev - 1);
  };

  return (
    <div className="flex max-h-[814px] flex-col overflow-hidden pt-3">
      {selectedGrouping?.length > 0 ? (
        <PromptAccordion
          accordionValue={accordionValue}
          setAccordionValue={setAccordionValue}
          promptList={currentPrompts}
          selectedPrompt={selectedPrompt}
          setSelectedPrompt={setSelectedPrompt}
        />
      ) : (
        <ul className="flex flex-col gap-1 overflow-auto">
          {currentPrompts.map((item: IPrompt, id: number) => {
            return (
              <Dialog key={item?.trace_id}>
                <DialogTrigger
                  asChild
                  onClick={() => setPosition(startIndex + id)}
                >
                  <li>
                    <PromptCard
                      selectedPrompt={selectedPrompt}
                      setSelectedPrompt={setSelectedPrompt}
                      item={item}
                    />
                  </li>
                </DialogTrigger>
                <PromptDetailsModal
                  position={position}
                  data={promptList}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                />
              </Dialog>
            );
          })}
        </ul>
      )}
    </div>
  );
};
