/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useLanguage } from '@/providers/LanguageProvider';
import { LucidePlus } from 'lucide-react';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import FilterLine from './filter-line';
import { PromptFilterGroupProps } from './prompt-interfaces';

const MAX_FILTERS = 3;

const PromptFilterGroup: React.FC<PromptFilterGroupProps> = ({
  group,
  onAddFilter,
  onRemoveFilter,
  onUpdateFilter,
  isFirstGroup,
  isLastGroup,
}) => {
  const { messages } = useLanguage();
  const langData = messages?.PromptFilters?.AddPromptCriteria;

  const canAddMoreFilters = group.filters.length < MAX_FILTERS;

  return (
    <>
      <div className="rounded-lg border border-[#40565A] bg-[#07181E]/[.24] px-3 pb-[10px] pt-3">
        {group.filters.map((filter, filterIndex) => (
          <div
            key={filter.id}
            className={`mb-[10px] ${filterIndex === 0 ? '' : 'ml-[9.7rem]'}`}
          >
            <div className="flex gap-3">
              {filterIndex === 0 && (
                <Select
                  value={filter.type}
                  onValueChange={(value) =>
                    onUpdateFilter(filter.id, { ...filter, type: value })
                  }
                >
                  <SelectTrigger className="ml-1 w-[160px]">
                    <SelectValue defaultValue="input" />
                  </SelectTrigger>
                  <SelectContent className="rounded-none bg-white text-[#201F1E]">
                    <SelectItem value="any" className="hover:bg-[#EDEBE9]">
                      {langData?.any}
                    </SelectItem>
                    <SelectItem value="input" className="hover:bg-[#EDEBE9]">
                      {langData?.input}
                    </SelectItem>
                    <SelectItem value="output" className="hover:bg-[#EDEBE9]">
                      {langData?.output}
                    </SelectItem>
                    <div className="flex items-center gap-1">
                      <SelectItem
                        value="system_prompt"
                        className="hover:bg-[#EDEBE9]"
                        disabled
                      >
                        {langData?.system_prompt}
                      </SelectItem>
                      <span className="rounded bg-[#EFDA1F] px-1.5 text-btn-label-sm text-[#07181E]">
                        {messages?.General?.wip}
                      </span>
                    </div>
                  </SelectContent>
                </Select>
              )}
              <FilterLine
                filter={filter}
                onRemove={() => onRemoveFilter(filter.id)}
                showRemoveButton={!(isFirstGroup && filterIndex === 0)}
                onUpdate={(updatedFilter) =>
                  onUpdateFilter(filter.id, updatedFilter)
                }
                isFirstGroup={isFirstGroup && filterIndex === 0}
              />
            </div>
          </div>
        ))}
        {canAddMoreFilters && (
          <div className="flex justify-center">
            <div
              className="inline-flex cursor-pointer items-center justify-center py-3 text-[13px] leading-[18px] text-link-active"
              onClick={onAddFilter}
            >
              <LucidePlus className="text-link-active" size={20} />
              {langData?.add_or_condition}
            </div>
          </div>
        )}
      </div>

      {!isLastGroup && (
        <div className="flex flex-col items-center">
          <hr className="h-3 border-l border-[#40565A]" />
          <div className="rounded bg-[#40565A] px-2 py-1 text-[13px] leading-[18px] text-white">
            {langData?.and}
          </div>
          <hr className="h-3 border-l border-[#40565A]" />
        </div>
      )}
    </>
  );
};

export default PromptFilterGroup;
